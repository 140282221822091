<template>
    <div class="alert">
        <breadcrumbs
            :settings="settingsBreadcrumbs"
        >
            <template #rightSide>
                <div
                    v-if="alertList.length"
                    class="btn-base btn-main alert-clear__btn"
                    @click="deleteAll"
                >
                    Clear all
                </div>
            </template>
            <template #total>
                <span
                    v-if="total > 0"
                    class="bc_info_balance"
                >
                    {{ total }}
                </span>
            </template>
        </breadcrumbs>
        <div class="alert_list">
            <div
                v-if="isShowEmptyAlertList"
                class="alert_nodata"
            >
                <p>Empty</p>
            </div>
            <radius-loader v-if="!isShowEmptyAlertList && isLoading" />
            <div
                ref="list"
                class="alert_list_body"
                @scroll="loadMore"
            >
                <transition-group
                    name="delete"
                    @after-leave="onLeaveCancelled"
                >
                    <div
                        v-for="(alert, index) in alertList"
                        :key="alert.id + alert.created_at"
                        class="alert_item"
                    >
                        <div
                            class="alert_item_delete"
                            @click="deleteOne(alert.id)"
                        >
                            <CloseIcon width="15px" />
                        </div>
                        <AlertItem
                            :data="alert"
                            :is-unread="index < countUnread"
                        />
                    </div>
                </transition-group>
                <div
                    v-if="isLoading"
                    class="alert_loader"
                >
                    <ButtonLoader />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
// Icons
import CloseIcon from 'mdi-vue/Close.vue';
// Alert types
import AlertItem from '@/components/Header/Alert/AlertItem'
import ButtonLoader from '@/components/common/ButtonLoader';
import Breadcrumbs from '@/components/Breadcrumbs';
import RadiusLoader from '@/components/common/RadiusLoader'

export default {
    components: {
        CloseIcon,
        AlertItem,
        ButtonLoader,
        Breadcrumbs,
        RadiusLoader
    },
    directives: {
        ClickOutside
    },
    data() {
        return {
            isLoading: true,
            alertList: [],
            page: 1,
            last_page: Infinity,
            current_page: 0,
            countUnread: 0,
            total: 0,
            isAnimationStop: false
        }
    },
    computed: {
        isShowEmptyAlertList() {
            if (this.isAnimationStop) {
                return true
            }
            return false
        },
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: breadcrumbs.name,
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        }
    },
    created() {
        this.getList()
        this.toggleBlock()
    },
    methods: {
        onLeaveCancelled() {
            if (!this.alertList.length && !this.isLoading) {
                this.isAnimationStop = true
            }
        },
        async toggleBlock() {
            try {
                await Api.put('/api/alert/read-all')
                    .then(() => {
                        setTimeout(() => {
                            this.countUnread = 0
                        }, 10000)
                    })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteOne(id) {
            try {
                await Api.delete('/api/alert/delete-one', { id })
                if (this.current_page !== this.last_page) {
                    const response = await this.getData()
                    this.alertList = response.data
                } else {
                    this.alertList = this.alertList.filter((item) => item.id !== id)
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async deleteAll() {
            try {
                await Api.delete('/api/alert/delete-all')
                const response = await this.getData()
                this.alertList = response.data
                this.countUnread = 0
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async getList() {
            try {
                this.isLoading = true
                const response = await this.getData()
                this.current_page = response.current_page
                this.last_page = response.last_page
                this.alertList = [...this.alertList, ...response.data]
                this.countUnread = response.counters.unread
                if (!this.alertList.length) {
                    this.isAnimationStop = true
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async getData() {
            try {
                const { data } = await Api.get('/api/alert/list', {
                    page: this.page,
                    per_page: 10
                })
                this.total = data.total
                return data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
                return false
            }
        },
        async loadMore() {
            const listElm = this.$refs.list
            const scrollHeightCheck = listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 1

            if (scrollHeightCheck && (!this.isLoading && this.last_page > this.page)) {
                this.page += 1
                await this.getList()
            }
        }
    }
}
</script>

<style lang="scss">
    .alert{
        position: relative;
        color: #1f2939;
        width: 100%;
        .bounce-bell {
            animation: bounce 1s infinite;
            svg {
                fill: $main-color;
                path {
                    stroke: $main-color;
                }
            }
        }
        .breadcrumbs-btns{
            display: flex;
            align-items: center;
        }
        &-clear__btn {
            width: 150px;
        }
        &_list{
            width: 100%;
            max-height: 600px;
            background: #fff;
            box-shadow: 0 5px 20px rgb(0 0 0 / 7%);
            border-radius: 4px;
            padding: 20px;
            overflow: hidden;
            z-index: 20;
            &_header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 20px;
            }
            &_body {
                overflow-x: hidden;
                overflow-y: scroll;
                max-height: calc(600px - 60px);
                margin-right: -20px;
                padding-right: 20px;
                &::-webkit-scrollbar {
                    width: 3px;
                    margin-right: -20px;
                }
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $main-color;
                    border-radius: 10px;
                }
            }
        }
        &__bubble {
            position: absolute;
            top: -10px;
            right: -5px;
            width: 18px;
            height: 18px;
            background: #1bb361;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            // margin: 0 7px;
            font-size: 12px;
            font-weight: 700;
        }
        &_title{
            font-size: 20px;
            font-weight: 700;
        }
        &_count{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($main-color, 0.1);
            color: $main-color;
            font-weight: 500;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            @media all and (max-width: 576px) {
                width: 35px;
                height: 35px;
                font-size: 14px;
                font-weight: 600;
            }
        }
        &_clear{
            font-size: 14px;
            cursor: pointer;
            transition: .3s all;
            &:hover {
                color: $main-color;
            }
        }
        &_item{
            position: relative;
            padding: 20px 0;
            border-bottom: 1px solid #dce2ea;
            &:last-child {
                border-bottom: none;
            }
            &_delete {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 20px;
                background-color: #ccc;
                border-radius: 50%;
                width: 30px;
                min-width: 30px;
                height: 30px;
                min-height: 30px;
                cursor: pointer;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            .alert_block{
                padding-bottom: 10px;
                cursor: pointer;
                &_header{
                    display: flex;
                    &_icon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgba($main-color, 0.1);
                        min-width: 50px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 15px;
                        .circle_unread{
                            width: 10px;
                            height: 10px;
                            background: #59c74d;
                            box-shadow: 0px 0px 5px 5px rgba(89, 199, 77, .3);
                            border-radius: 50%;
                            position: absolute;
                            top: 5px;
                            right: 0px;
                        }
                        .avatar-wrapper{
                            width: 50px;
                            height: 50px;
                            .avatar-img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
                &_body{
                    font-weight: 400;
                    font-size: 16px;
                    color: #1e2838;
                    margin-top: 10px;
                    line-height: 1.2;
                    padding: 20px;
                    background: #f4f4f4;
                    border-radius: 0 10px 10px 10px;
                    border: 1px solid #f4f4f4;
                    transition: .2s;
                    &:hover{
                        background: rgba($main-color, 0.1);
                        border: 1px solid $main-color;
                    }
                }
                &_title{
                    font-weight: 700;
                    font-size: 16px;
                    color: #1f2939;
                    padding-right: 30px;
                }
                &_date{
                    display: flex;
                    flex-direction: column;
                    color: #afb2b9;
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 10px;
                    span {
                        &:first-child {
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }
        &_loader{
            display: flex;
            justify-content: center;
        }
        &_nodata{
            text-align: center;
            position: relative;
            font-weight: bold;
            &:before {
                content: '';
                display: block;
                width: 50px !important;
                height: 50px !important;
                background-image: url('~@/assets/img/svg/billing_notfound.svg');
                background-size: 100% 100%;
                margin: 0 auto;
                margin-bottom: 20px;
            }
        }
    }
</style>
